import React from 'react';
import {Bar} from 'react-chartjs-2';
import styles from "./SubChart.module.css";

export default ({data, title, addr}) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: false,
            text: title
        },
        tooltips: {
            mode: 'index',
            intersect: true
        },
        scales: {
            yAxes: [{
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
            }, {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                    drawOnChartArea: false
                }
            }],
        }
    };

    return (
      <div className={styles.chart}>
        <h6>{title}</h6>
        <Bar data={data} options={options} />
      </div>
    )
}

// <a href={addr} className={styles.chartlink} target="_blank" rel="noopener noreferrer">{title}</a>