import React from 'react';
import IndexSubChart from './charts/IndexSubChart';
import styles from "./RankingRow.module.css";
import { Row, Col, Table } from 'react-bootstrap';
import { links } from "../utils/utils";

function renderStockList(stock_list) {
    return (
        <Table hover size="sm">
            <thead>
                <tr>
                    <th></th>
                    <th>代號</th>
                    <th>名稱</th>
                    <th>收盤價</th>
                    <th>漲跌幅</th>
                    <th>成交額(億)</th>
                    <th>成交張數</th>
                    <th>發行張數</th>
                </tr>
            </thead>
            <tbody>
                {stock_list.map((item, index) => {
                    return (
                        <tr id={"tr_" + item.stock_id} key={item.stock_id}>
                            <td>{index + 1}</td>
                            <td>
                                <a href={links.statementdog + item.stock_id} target="_blank" rel="noopener noreferrer">{item.stock_id}</a>
                            </td>
                            <td>
                                <a href={links.wantgoo + item.stock_id} target="_blank" rel="noopener noreferrer">{item.name}</a>
                            </td>
                            <td>{item.price}</td>
                            <td>{item.change_ratio}</td>
                            <td>{item.turnover}</td>
                            <td>{item.trade_volume}</td>
                            <td>{item.stock_issued}</td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

export default ({chart_data, title, subtitle, addr, stock_list}) => {
    return (
        <Row className={styles.rank_div} >
            <Col sm className="mb-5">
                <IndexSubChart data={chart_data} title={title} subtitle={subtitle} addr={addr}/>
            </Col>
            <Col sm={7} className={[styles.table_div, "mt-5"].join(' ')}>
                {renderStockList(stock_list)}
            </Col>
        </Row>
    )
}
