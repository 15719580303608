import React from "react";
import Helmet from "react-helmet";
import og_image from "./joyride_og.png";

export default ({description, title, url}) => {
  return (
    <Helmet>
        <title>{"Joyride - " + title}</title>
        <meta name="description" content={description} />
        <meta name="distribution" content="Taiwan" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={og_image} />
        <meta property="og:image:alt" content="Joyride" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
    </Helmet>
  );
}