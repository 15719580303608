export const chartColors = {
    white: 'rgb(255, 255, 255)',
    silver: 'rgb(192, 192, 192)',
    grey: 'rgb(128, 128, 128)',
    black: 'rgb(0, 0, 0)',
    red: 'rgb(255, 99, 132)',
    maroon: 'rgb(142, 51, 51)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    olive: 'rgb(131, 131, 49)',
    lime: 'rgb(92, 255, 92)',
    green: 'rgb(48, 131, 48)',
    aqua: 'rgb(99, 255, 255)',
    teal: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    navy: 'rgb(45, 45, 131)',
    fuchsia: 'rgb(254, 90, 254)',
    purple: 'rgb(153, 102, 255)',
    red2: 'rgb(231, 76, 60)',
    purple2: 'rgb(142, 68, 173)',
    blue2: 'rgb(52, 152, 219)',
    teal2: 'rgb(22, 160, 133)',
    green2: 'rgb(46, 204, 113)',
    yellow2: 'rgb(243, 156, 18)',
    orange2: 'rgb(211, 84, 0)',
};

export const links = {
    statementdog: "https://statementdog.com/analysis/",
    wantgoo: "https://www.wantgoo.com/stock/",
    goodinfo_twse: "https://goodinfo.tw/StockInfo/StockList.asp?MARKET_CAT=上市&INDUSTRY_CAT=",
    goodinfo_tpex: "https://goodinfo.tw/StockInfo/StockList.asp?MARKET_CAT=上櫃&INDUSTRY_CAT=",
    investor_conference: "https://mops.twse.com.tw/mops/web/t100sb07_1",
    joyride_sheet:"https://docs.google.com/forms/d/e/1FAIpQLScAAQhURo0tNhUmeDkbs9zkASlIDQzyjvyQRnXZclR_NRnZZg/viewform",
    ig:"https://www.instagram.com/joyride.tw/",
    
};