import React from "react";
import AdSense from "react-adsense";
import "../utils/bootstrap.min.css";

export default () => {
    return (
    <div className="text-center">
        <AdSense.Google
            client='ca-pub-6595467991662776'
            slot='4012420482'
            style={{display: 'inline-block', width: 728, height: 90}}
            format=''
        />
    </div>
    )
}