import React from "react";
import TpexIndexPanel from "./panels/TpexIndexPanel";
import LoadingPanel from './panels/LoadingPanel';
import ErrorPanel from './panels/ErrorPanel';
import Seo from "../components/Seo";

class TpexIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                error: null,
                isload:false,
                items:[]
            }
        }
    }

    componentDidMount() {
        console.log("componentDidMount");
        fetch("/json/tpex_index.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    renderPanel() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            console.log("error");
            return (
                <ErrorPanel data={error.message}/>
            );
        } else if (!isLoaded) {
            console.log("loading");
            return (
                <LoadingPanel />
            );
        } else {
            console.log("normal start");
            return (
                <TpexIndexPanel data={items} />
            );
        }
    }

    render() {
        return (
            <div>
                <Seo
                    title="追蹤上櫃產業"
                    description="追蹤上櫃類股籌碼資金流向, 研判類股未來走勢. 每日更新類股成交比重, 類股漲跌幅排名, 類股成交比重增減幅排名."
                    url="https://joyride.tw/tpex-index"
                    />
                {this.renderPanel()}
            </div>
        );
    }
}

export default TpexIndex