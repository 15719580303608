import React from "react";
import {Link} from "react-router-dom";
import "./Footer.module.css";
import '../utils/bootstrap.min.css';

export default ({ children }) => {
  return (
    <footer className="py-2">
        <div className="container">
          <Link to="/about">關於 Joyride</Link> |
          連絡 Joyride : <a href="mailto:cs@joyride.tw">cs@joyride.tw</a>
          <br />
          <span className="text-muted">網站內提供之數據資料與內容僅供使用者參考, 所有資訊以台灣證券交易所公告資料為準. 任何交易行為須自行判斷並承擔風險, 本網站不負擔盈虧之法律責任.</span>
          <br />
          <span className="text-muted">如需轉載內容務必經由本公司同意.</span>
          <br />
          <span className="text-muted">Copyright © 2024 Joyride.tw</span>
        </div>
    </footer>
  )
}