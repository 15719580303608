import React from "react";
import {Row, Col} from 'react-bootstrap';
import MainChart from '../../components/charts/MainChart';
import SubChart from '../../components/charts/SubChart';
import {chartColors} from "../../utils/utils";
import AdSense from "react-adsense";

function genMainChartData(date, index, vol) {
    var data = {
        labels: date,
        datasets: [{
            type: 'line',
            label: '加權股價指數',
            data: index,
            borderColor: chartColors.blue,
            borderWidth: 2,
            fill: false,
            yAxisID: 'y-axis-1'
        }, {
            type: 'bar',
            label: '成交量',
            data: vol,
            backgroundColor: chartColors.red,
            borderWidth: 2,
            yAxisID: 'y-axis-2'
        }]
    };
    return data;
}

function genSubChartData(date, index, sub_data, sub_title) {
    var data = {
        labels: date,
        datasets: [{
            type: 'line',
            label: '加權股價指數',
            data: index,
            borderColor: chartColors.blue,
            borderWidth: 2,
            fill: false,
            yAxisID: 'y-axis-1'
        }, {
            type: 'bar',
            label: sub_title,
            data: sub_data,
            backgroundColor: chartColors.silver,
            borderWidth: 2,
            yAxisID: 'y-axis-2'
        }]
    };

    return data;
}

function genSubChartData2Bar(date, index, sub_data, sub_data2, sub_title, sub_title2) {
    var data = {
        labels: date,
        datasets: [{
            type: 'line',
            label: '加權股價指數',
            data: index,
            borderColor: chartColors.blue,
            borderWidth: 2,
            fill: false,
            yAxisID: 'y-axis-1'
        }, {
            type: 'bar',
            label: sub_title,
            data: sub_data,
            backgroundColor: chartColors.silver,
            borderWidth: 2,
            yAxisID: 'y-axis-2'
        }, {
            type: 'bar',
            label: sub_title2,
            data: sub_data2,
            backgroundColor: chartColors.grey,
            borderWidth: 2,
            yAxisID: 'y-axis-2'
        }]
    };

    return data;
}

var date = [],
    index = [],
    vol = [],
    margin_trading = [],
    short_selling = [],
    ms_ratio = [],
    f_spot = [],
    i_spot = [],
    d_spot = [],
    three_spot = [],
    f_feature = [],
    f_three = [],
    f_buy = [],
    d_buy = [],
    f_sell = [],
    d_sell = [],
    pc_ratio = [],
    lt_5 = [],
    lt_5_f = [],
    lt_5_se = [],
    lt_5_se_f = [],
    lt_10 = [],
    lt_10_f = [],
    lt_10_se = [],
    lt_10_se_f = [],
    mtx = [],
    mtx_ratio = [];

function parseJsonData(data) {
    date = data.date;
    index = data.index;
    vol = data.vol;
    margin_trading = data.margin_trading;
    short_selling = data.short_selling;
    ms_ratio = data.ms_ratio;
    f_spot = data.f_spot;
    i_spot = data.i_spot;
    d_spot = data.d_spot;
    three_spot = data.three_spot;
    f_feature = data.f_feature;
    f_three = data.f_three;
    f_buy = data.f_buy;
    d_buy = data.d_buy;
    f_sell = data.f_sell;
    d_sell = data.d_sell;
    pc_ratio = data.pc_ratio;
    lt_5 = data.lt_5;
    lt_5_f = data.lt_5_f;
    lt_5_se = data.lt_5_se;
    lt_5_se_f = data.lt_5_se_f;
    lt_10 = data.lt_10;
    lt_10_f = data.lt_10_f;
    lt_10_se = data.lt_10_se;
    lt_10_se_f = data.lt_10_se_f;
    mtx = data.mtx;
    mtx_ratio = data.mtx_ratio;
}

function subChartInCol(data, title, addr) {
    return (
        <Col sm className="mt-3 mb-5">
            <SubChart data={data} title={title} addr={addr} />
        </Col>
    );
}

function adInCol() {
    return (
        <Col sm className="mt-3 text-center">
            <AdSense.Google
                client='ca-pub-6595467991662776'
                slot='6511192639'
                style={{ width: 330, height: 200, display: 'inline-block' }}
                format=''
            />
        </Col>
    );
}

function dataBrief(title, data) {
    let today_data = data[data.length - 1];
    let yesterday_data = data[data.length - 2];
    let diff = parseFloat((today_data - yesterday_data).toFixed(2));
    if (diff > 0) {
        return (
            <span>{title}: {today_data} <span className="text-danger">(+{diff})</span></span>
        );
    } else if (diff < 0) {
        return (
            <span>{title}: {today_data} <span className="text-success">({diff})</span></span>
        );
    } else if (isNaN(diff)) {
        return (
            <span>{title}: - </span>
        );
    } else {
        return (
            <span>{title}: {today_data} <span className="text-secondary">({diff})</span></span>
        );
    }
}

function dataBriefReverse(title, data) {
    let today_data = data[data.length - 1];
    let yesterday_data = data[data.length - 2];
    let diff = parseFloat((today_data - yesterday_data).toFixed(2));
    if (diff > 0) {
        return (
            <span>{title}: {today_data} <span className="text-success">(+{diff})</span></span>
        );
    } else if (diff < 0) {
        return (
            <span>{title}: {today_data} <span className="text-danger">({diff})</span></span>
        );
    } else if (isNaN(diff)) {
        return (
            <span>{title}: - </span>
        );
    } else {
        return (
            <span>{title}: {today_data} <span className="text-secondary">({diff})</span></span>
        );
    }
}

function indexDiff(diff) {
    if (diff > 0) {
        return (
            <span className="text-danger">+{diff}</span>
        );
    } else if (diff < 0) {
        return (
            <span className="text-success">{diff}</span>
        );
    } else if (isNaN(diff)) {
        return (
            <span> - </span>
        );
    } else {
        return (
            <span className="text-secondary">{diff}</span>
        );
    }
}

export default ({data}) => {
    parseJsonData(data);
    const vol_data = genMainChartData(date, index, vol);

    var date_no_year = date.map(d => d.substring(5));

    var date30 = date_no_year.slice(-30);
    var index30 = index.slice(-30);
    var date60 = date_no_year.slice(-60);
    var index60 = index.slice(-60);
    const mt_data = genSubChartData(date30, index30, margin_trading.slice(-30), "融資餘額");
    const ss_data = genSubChartData(date30, index30, short_selling.slice(-30), "融劵餘額");
    const ms_data = genSubChartData(date30, index30, ms_ratio.slice(-30), "資卷比");

    const three_spot_data = genSubChartData2Bar(date60, index60, three_spot,
            f_spot, "三大法人現貨", "外資現貨");
    const i_spot_data = genSubChartData(date60, index60, i_spot, "投信現貨");

    const f_three_data = genSubChartData(date60, index60, f_three, "三大法人期貨");
    const f_feature_data = genSubChartData(date60, index60, f_feature, "外資期貨");

    const pc_ratio_data = genSubChartData(date60, index60, pc_ratio, "P/C Ratio");
    
    const option_buy_data = genSubChartData2Bar(date60, index60, f_buy,
            d_buy, "外資選擇權買權", "自營商選擇權買權");
    const option_sell_data = genSubChartData2Bar(date60, index60, f_sell,
            d_sell, "外資選擇權賣權", "自營商選擇權賣權");

    const lt5_data = genSubChartData2Bar(date60, index60, lt_5,
            lt_5_f, "前五大交易人近月", "前五大交易人所有");
    const lt10_data = genSubChartData2Bar(date60, index60, lt_10,
            lt_10_f, "前十大交易人近月", "前十大交易人所有");
    const lt5_se_data = genSubChartData2Bar(date60, index60, lt_5_se,
            lt_5_se_f, "前五特定法人近月", "前五特定法人所有");
    const lt10_se_data = genSubChartData2Bar(date60, index60, lt_10_se,
            lt_10_se_f, "前十特定法人近月", "前十特定法人所有");

    const mtx_ratio_data = genSubChartData(date60, index60, mtx_ratio, "散戶多空比");

    return (
        <div className="container">
            <h4>{data.info.date}</h4>
            <h5>
                {dataBrief("加權股價指數", index)}{" "}
                漲跌百分比: {indexDiff(data.info.qc)}%{" "}
                {dataBrief("成交金額(億)", vol)}
            </h5>
            <h6>{data.info.brief}</h6>
            <MainChart data={vol_data} title="加權指數"/>
            <h5 className="mt-4">
                {dataBrief("融資餘額(億)", margin_trading)}{" "}
                {dataBrief("融劵餘額", short_selling)}{" "}
                {dataBrief("資卷比", ms_ratio)}%
            </h5>
            <Row>
                {subChartInCol(mt_data, "融資餘額 (億)", "#")}
                {subChartInCol(ss_data, "融劵餘額", "#")}
                {subChartInCol(ms_data, "資卷比", "#")}
            </Row>
            <hr />
            <h5>
                {dataBrief("三大法人現貨", three_spot)}{" "}
                {dataBrief("外資現貨", f_spot)}{" "}
                {dataBrief("投信現貨", i_spot)}
            </h5>
            <Row>
                {subChartInCol(three_spot_data, "外資現貨", "#")}
                {subChartInCol(i_spot_data, "投信現貨", "#")}
            </Row>
            <hr />
            <h5>
                {dataBrief("三大法人期貨", f_three)}{" "}
                {dataBrief("外資期貨", f_feature)}
            </h5>
            <Row>
                {subChartInCol(f_three_data, "三大法人期貨", "#")}
                {subChartInCol(f_feature_data, "外資期貨", "#")}
            </Row>
            <hr />
            <h5>
                {dataBrief("P/C Ratio", pc_ratio)}
            </h5>
            <Row>
                {subChartInCol(pc_ratio_data, "P/C Ratio", "#")}
                {adInCol()}
            </Row>
            <h5>
                {dataBrief("外資選擇權買權", f_buy)}{" "}
                {dataBrief("自營商選擇權買權", d_buy)}<br />
                {dataBrief("外資選擇權賣權", f_sell)}{" "}
                {dataBrief("自營商選擇權賣權", d_sell)}
            </h5>
            <Row>
                {subChartInCol(option_buy_data, "選擇權買權", "#")}
                {subChartInCol(option_sell_data, "選擇權賣權", "#")}
            </Row>
            <hr />
            <h5>
                {dataBrief("前五大交易人近月", lt_5)}{" "}
                {dataBrief("前五大交易人所有", lt_5_f)}<br />
                {dataBrief("前十大交易人近月", lt_10)}{" "}
                {dataBrief("前十大交易人所有", lt_10_f)}
            </h5>
            <Row>
                {subChartInCol(lt5_data, "前五大交易人", "#")}
                {subChartInCol(lt10_data, "前十大交易人", "#")}
            </Row>
            <h5>
                {dataBrief("前五特定法人近月", lt_5_se)}{" "}
                {dataBrief("前五特定法人所有", lt_5_se_f)}<br />
                {dataBrief("前十特定法人近月", lt_10_se)}{" "}
                {dataBrief("前十特定法人所有", lt_10_se_f)}
            </h5>
            <Row>
                {subChartInCol(lt5_se_data, "前五特定法人", "#")}
                {subChartInCol(lt10_se_data, "前十特定法人", "#")}
            </Row>
            <hr />
            <h5>
                {dataBriefReverse("散戶多空比", mtx_ratio)}
            </h5>
            <Row>
                {subChartInCol(mtx_ratio_data, "散戶多空比", "#")}
                {adInCol()}
            </Row>
        </div>
    )
}