import React from "react";
import Seo from "../components/Seo";
import '../styles/index.css';
import { links } from "../utils/utils";
import joyride_pic from "../components/joyride.png";
import ig_icon from "../components/ig_icon.png";


export default () => {
    return (
        <div className="outer">
            <div className="container">
                <Seo
                    title="喜行策略"
                    description="Joyride 喜行策略 致力於開發台股與加密貨幣量化交易策略, 透過大數據分析與回測, 降低風險, 提升投資效率."
                    url="https://joyride.tw/"
                />
                <div className="vertical-down">
                    <a href="/blue-strategy">
                        <img className="logo mb-2" src={joyride_pic} alt="Joyride" />
                    </a>
                    <div>
                        <a href={links.ig} target="_blank" rel="noopener noreferrer">
                            <img className="social-icon ml-2" src={ig_icon} alt="intragram" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}