import React from "react";
import MainChart from '../../components/charts/MainChart';
import SubChart from "../../components/charts/SubChart";
import AdSenseBanner from "../../components/AdSenseBanner";
import {Button, Row, Col, Table} from "react-bootstrap";
import styles from "../../components/RankingRow.module.css";
import {chartColors} from "../../utils/utils";

var date = [],
    index = [],
    stock_id = [],
    stock_name = [],
    sell_price = [],
    roi = [],
    account = [],
    mdd = [],
    stock_list = [],
    avgroi = [],
    buydate = [];

function parseJsonData(data) {
    date = data.date;
    index = data.index;
    stock_id = data.stock_id;
    stock_name = data.stock_name;
    sell_price = data.sell_price;
    roi = data.roi;
    account = data.account;
    mdd = data.mdd;
    avgroi = data.avgroi;
    buydate = data.buydate;


    for (let i = 0; i < buydate.length; i++) {
        if (stock_id[i] !== " ") {
            var dt = {"stock_id": stock_id[i], "stock_name": stock_name[i], "sell_price": sell_price[i], "roi": roi[i], "buydate": buydate[i]};
            stock_list.push(dt);
        }
    }
    stock_list.reverse();
}

class GreenStrategyPanel extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            filter: "30",
        };
        parseJsonData(this.props.data);
    }

    genMainChartData(date, index, avgroi) {
        var data = {
            labels: date,
            datasets: [{
                type: 'line',
                label: '加權股價指數',
                data: index,
                borderColor: chartColors.blue,
                borderWidth: 1,
                fill: false,
                yAxisID: 'y-axis-1'
            }, {
                type: 'bar',
                label: '策略每日交易平均報酬率',
                data: avgroi,
                backgroundColor: chartColors.red,
                borderWidth: 1,
                yAxisID: 'y-axis-2'
            }]
        };
        return data;
    }

    genSubChartData(date, main_data, sub_data, sub_title) {
        return ({
            labels: date,
            datasets: [{
                type: 'line',
                label: '加權股價指數',
                data: main_data,
                borderColor: chartColors.blue,
                borderWidth: 1,
                fill: false,
                yAxisID: 'y-axis-1'
            }, {
                type: 'bar',
                label: sub_title,
                data: sub_data,
                borderColor: chartColors.orange,
                borderWidth: 2,
                stepped: true,
                fill: false,
                yAxisID: 'y-axis-2'
            }]
        });
    }
    
    briefInCol(data) {
        return (
            <div>
                總交易次數: {data.total_count} {" | "}
                交易平均報酬率: {data.avg_roi} % {" | "}
                凱利公式: {data.kelly} {" | "}
                最大獲利率: {data.max_pr} % {" | "}
                最大虧損率: {data.min_pr} %
            </div>
        );
    }

    renderStockList(stock_list) {
        return (
            <Table hover size="sm">
                <thead>
                    <tr>
                        <th>買回日期</th>
                        <th>代號</th>
                        <th>名稱</th>
                        <th>買回價格</th>
                        <th>預估最大報酬率</th>
                    </tr>
                </thead>
                <tbody>
                    {stock_list.map((item, index) => {
                        return (
                            <tr id={"trb_" + index} key={index}>
                                <td>{item.buydate}</td>
                                <td>{item.stock_id}</td>
                                <td>{item.stock_name}</td>
                                <td>{item.sell_price}</td>
                                <td>{item.roi}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }

    handleClick(e) {
        this.setState({filter: e.target.value});
    }
 
    render() {
        let filter = this.state.filter;
        let brief = "";
        let stock_list_ = stock_list;
        switch (filter) {
            case "30":
                brief = this.props.data.info.brief_30;
                break;
            case "15":
                brief = this.props.data.info.brief_15;
                stock_list_ = stock_list.slice(0, brief.total_count);

                break;
            default:
                console.log("unknown");
        }

        console.log("render");
        return (
            <div className="container">
                <span className="text-success strategy-title">Joyride 綠色策略</span>
                <p className="mt-2">放空型策略</p>

                <MainChart data={this.genMainChartData(date, index, avgroi)} title="Joyride 綠色策略"/>

                <Row className="mb-5">
                    <Col sm className="mt-5">
                        <SubChart data={this.genSubChartData(date, index, account, "累積報酬率")} title="累積報酬率" addr="#"/>
                    </Col>
                    <Col sm className="mt-5">
                        <SubChart data={this.genSubChartData(date, index, mdd, "最大回檔")} title="最大回檔" addr="#"/>
                    </Col>
                </Row>
                <hr />
                <AdSenseBanner />

                <Row className="mr-auto mb-4">
                    <Col sm>{this.briefInCol(brief)}</Col>
                    <Button variant="primary" className="mr-2" size="sm" onClick={this.handleClick} value="30">30 天期</Button>
                    <Button variant="primary" className="mr-2" size="sm" onClick={this.handleClick} value="15">15 天期</Button>
                </Row>

                <Row className={styles.rank_div} >
                    <Col sm className={styles.table_div}>
                        {this.renderStockList(stock_list_)}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default GreenStrategyPanel