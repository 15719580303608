import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';

//pages
import Market from './pages/Market';
import Chips from './pages/Chips';
import TwseIndex from './pages/TwseIndex';
import TpexIndex from './pages/TpexIndex';
import BlueStrategy from './pages/BlueStrategy';
import PurpleStrategy from './pages/PurpleStrategy';
import GreenStrategy from './pages/GreenStrategy';
import About from './pages/About';
import Home from './pages/Home';

ReactDOM.render(
    <BrowserRouter>
        <Header />
        <div className="main">
            <Switch>
                <Route path="/tw-stock-market">
                    <Market />
                </Route>
                <Route path="/tw-stock-chips">
                    <Chips />
                </Route>
                <Route path="/twse-market-indices">
                    <TwseIndex />
                </Route>
                <Route path="/tpex-market-indices">
                    <TpexIndex />
                </Route>
                <Route path="/blue-strategy">
                    <BlueStrategy />
                </Route>
                <Route path="/purple-strategy">
                    <PurpleStrategy />
                </Route>
                <Route path="/green-strategy">
                    <GreenStrategy />
                </Route>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </div>
        <Footer />
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();