import React from "react";
import BlueStrategyPanel from "./panels/BlueStrategyPanel";
import LoadingPanel from './panels/LoadingPanel';
import ErrorPanel from './panels/ErrorPanel';
import Seo from "../components/Seo";

class BlueStrategy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                error: null,
                isload:false,
                items:[]
            }
        }
    }

    componentDidMount() {
        console.log("componentDidMount");
        fetch("/json/blue_strategy.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    renderPanel() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            console.log("error");
            return (
                <ErrorPanel data={error.message}/>
            );
        } else if (!isLoaded) {
            console.log("loading");
            return (
                <LoadingPanel />
            );
        } else {
            console.log("normal start");
            return (
                <BlueStrategyPanel data={items} />
            );
        }
    }


    render() {
        return (
            <div>
                <Seo
                    title="Joyride 藍色策略"
                    description="Joyrdie 藍色策略, 右側交易追高型策略."
                    url="https://joyride.tw/blue-strategy"
                    />
                {this.renderPanel()}
            </div>
        );
    }
}

export default BlueStrategy