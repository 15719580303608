import React from "react";
import MarketPanel from "./panels/MarketPanel";
import LoadingPanel from "./panels/LoadingPanel";
import ErrorPanel from "./panels/ErrorPanel";
import Seo from "../components/Seo";

class Market extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                error: null,
                isload:false,
                items:[]
            }
        }
    }

    componentDidMount() {
        console.log("componentDidMount");
        fetch("/json/market.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    renderPanel() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            console.log("error");
            return (
                <ErrorPanel data={error.message}/>
            );
        } else if (!isLoaded) {
            console.log("loading");
            return (
                <LoadingPanel />
            );
        } else {
            console.log("normal start");
            return (
                <MarketPanel data={items} />
            );
        }
    }

    render() {
        return (
            <div>
                <Seo
                    title="追蹤大盤籌碼"
                    description="追蹤大盤籌碼, 解讀台股多空指標. 每日更新法人現貨, 期貨未平倉口數, 選擇權未平倉餘額, P/C Ratio, 期貨大額交易人未沖銷部位, 散戶多空比, 融資餘額, 融劵餘額, 資卷比."
                    url="https://joyride.tw/"
                    />
                {this.renderPanel()}
            </div>
        );
    }
}

export default Market