import React from 'react';
import {Bar} from 'react-chartjs-2';
import styles from "./MainChart.module.css";

export default ({data, title}) => {

    const data1 = data.datasets[0].data;
    const data1_max = Math.abs(Math.max(...data1));
    const data1_min = Math.abs(Math.min(...data1));

    let tick_d1 = Math.ceil(data1_max > data1_min ? data1_max : data1_min);
    let tick_d2 = tick_d1;

    if (data.datasets.length > 1) {
        const data2 = data.datasets[1].data;
        const data2_max = Math.abs(Math.max(...data2));
        const data2_min = Math.abs(Math.min(...data2));
        tick_d2 = Math.ceil(data2_max > data2_min ? data2_max : data2_min);
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: false,
            text: title
        },
        tooltips: {
            mode: 'index',
            intersect: true
        },
        scales: {
            yAxes: [{
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                ticks: {
                    min: tick_d1*-1,
                    max: tick_d1,
                }
            }, {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                ticks: {
                    min: tick_d2*-1,
                    max: tick_d2,
                },
                gridLines: {
                    drawOnChartArea: false
                }
            }],
        }
    };

    return (
      <div className={styles.chart}>
        <Bar data={data} options={options} />
      </div>
    )
}