import React from "react";
import RankingRow from "../../components/RankingRow";
import DoughnutChart from "../../components/charts/DoughnutChart";
import IndexMainChart from "../../components/charts/IndexMainChart";
import AdSenseBanner from "../../components/AdSenseBanner";
import { Button, Row, Col} from "react-bootstrap";
import { chartColors, links } from "../../utils/utils";


var date = [];
var tpex_labels = [];
var tpex_objs = [];
var tpex_today_vol = [];

function parseJsonData(data) {
    date = data['date'];
    tpex_labels = Object.keys(data['vols']);
    let indexes = data['indexes'];
    let vols = Object.values(data['vols']);
    let stock_list = data['stock_list'];

    tpex_today_vol = [];
    let tpex_yesterday_vol = [];
    let today_index = vols[0].length - 1;
    let yesterday_index = today_index - 1;
    for (let i = 0; i < vols.length; i++) {
        tpex_today_vol.push(parseFloat(vols[i][today_index]));
        tpex_yesterday_vol.push(parseFloat(vols[i][yesterday_index]));
    }

    let today_sum = tpex_today_vol.reduce((a, b) => a + b, 0);
    let todays_weight = tpex_today_vol.map(x => (x * 100 / today_sum).toFixed(3));

    let yesterday_sum = tpex_yesterday_vol.reduce((a, b) => a + b, 0);
    let yesterday_weight = tpex_yesterday_vol.map(x => (x * 100 / yesterday_sum).toFixed(3));

    tpex_objs = [];
    for (let i = 0; i < tpex_labels.length; i++) {
        let weight_rise = 0;
        if (yesterday_weight[i] != 0) {
            weight_rise = ((todays_weight[i] - yesterday_weight[i]) * 100 / yesterday_weight[i]).toFixed(2);
        }

        if (todays_weight[i] == 0 && yesterday_weight[i] == 0) {
            weight_rise = 0;
        }

        let index_list = [];
        let index_change = 0;
        if (tpex_labels[i] in indexes) {
            index_list =  indexes[tpex_labels[i]];
        }
        if (index_list.length > 0)
            index_change = ((parseFloat(index_list[today_index]) - parseFloat(index_list[yesterday_index])) / parseFloat(index_list[yesterday_index]) * 100).toFixed(2);

        //console.log(tpex_labels[i], stock_list[tpex_labels[i]]);

        tpex_objs.push({
            title: tpex_labels[i],
            index: index_list,
            vol: vols[i],
            link: links.goodinfo_tpex + tpex_labels[i],
            index_change: index_change,
            weight: todays_weight[i],
            weight_rise: weight_rise,
            stock_list: stock_list[tpex_labels[i]],
        });
    }
}

class TpexIndexPanel extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            filter: "weight_rise_rank",
        };
        parseJsonData(this.props.data);
    }

    handleClick(e) {
        this.setState({filter: e.target.value});
    }
    
    genDoughnutChartData(labels, data) {
        const bgcolor = [
            chartColors.teal,
            chartColors.orange,
            chartColors.silver,
            chartColors.red,
            chartColors.blue,
            chartColors.yellow,
            chartColors.olive,
            chartColors.lime,
            chartColors.green,
            chartColors.aqua,
            chartColors.blue2,
            chartColors.maroon,
            chartColors.navy,
            chartColors.fuchsia,
            chartColors.yellow2,
            chartColors.purple,
            chartColors.red2,
            chartColors.purple2,
            chartColors.green2,
            chartColors.silver,
            chartColors.red,
            chartColors.maroon,
            chartColors.teal2,
            chartColors.orange2,
            chartColors.yellow,
            chartColors.lime,
            chartColors.green2
        ];

        return({
            labels: labels,
            datasets: [{
                data: data,
                backgroundColor: bgcolor,
                label: 'dataset'
            }]
        });
    }

    genSubChartData(date, main_data, main_title, sub_data, sub_title, index_change) {
        return ({
            labels: date,
            datasets: [{
                type: 'line',
                label: main_title,
                data: main_data,
                borderColor: index_change < 0 ? chartColors.green : chartColors.red,
                borderWidth: 2,
                fill: false,
                yAxisID: 'y-axis-1'
            }, {
                type: 'bar',
                label: sub_title,
                data: sub_data,
                backgroundColor: chartColors.silver,
                borderWidth: 2,
                yAxisID: 'y-axis-2'
            }]
        });
    }

    genIndexMainChartData(labels, index_change, weight, title) {
        if (weight.length === 0) {
            return ({
                labels: labels,
                datasets: [{
                    type: 'bar',
                    label: "指數漲幅",
                    data: index_change,
                    backgroundColor: chartColors.blue,
                    borderWidth: 2,
                    yAxisID: 'y-axis-1'
                }]
            });
        } else {
            return ({
                labels: labels,
                datasets: [{
                    type: 'bar',
                    label: "指數漲幅",
                    data: index_change,
                    backgroundColor: chartColors.blue,
                    borderWidth: 2,
                    yAxisID: 'y-axis-1'
                }, {
                    type: 'bar',
                    label: title,
                    data: weight,
                    backgroundColor: chartColors.grey,
                    borderWidth: 2,
                    yAxisID: 'y-axis-2'
                }]
            });
        }
    }
 
    renderRankingList() {
        let date_no_year = date.map(d => d.substring(5));
        let rankingList = [];
        for (let i = 0; i < tpex_objs.length; i++) {
            let chart_data = this.genSubChartData(date_no_year, tpex_objs[i].index, tpex_objs[i].title, tpex_objs[i].vol, "成交量", tpex_objs[i].index_change);
            let title = (i+1).toString() + ". " + tpex_objs[i].title;
            let subtitle = "指數漲幅%:" + tpex_objs[i].index_change + " 成交比重%:" + tpex_objs[i].weight + " 成交比重增幅%:" + tpex_objs[i].weight_rise;
            rankingList.push(
                <RankingRow key={tpex_objs[i].title} chart_data={chart_data} title={title} subtitle={subtitle} addr={tpex_objs[i].link} stock_list={tpex_objs[i].stock_list} />
            );
        }
        return rankingList;
    }

    render() {
        console.log("render");

        let filter = this.state.filter;
        let title = "", label = [], index_change = [], weight = [];
        switch (filter) {
            case 'index_rank':
                tpex_objs.sort(function (a, b) {
                    return b.index_change - a.index_change;
                });
                for (let i = 0; i < tpex_objs.length; i++) {
                    label.push(tpex_objs[i].title);
                    index_change.push(tpex_objs[i].index_change);
                }
                break;
            case 'weight_rank':
                tpex_objs.sort(function (a, b) {
                    return b.weight - a.weight;
                });
                title = "成交比重";
                for (let i = 0; i < tpex_objs.length; i++) {
                    label.push(tpex_objs[i].title);
                    index_change.push(tpex_objs[i].index_change);
                    weight.push(tpex_objs[i].weight);
                }
                break;
            case 'weight_rise_rank':
                tpex_objs.sort(function (a, b) {
                    return b.weight_rise - a.weight_rise;
                });
                title = "成交比重增幅";
                for (let i = 0; i < tpex_objs.length; i++) {
                    label.push(tpex_objs[i].title);
                    index_change.push(tpex_objs[i].index_change);
                    weight.push(tpex_objs[i].weight_rise);
                }
                break;
            default:
                console.log("unknown");
        }

        return (
            <div className="container">
                <div>
                    <h4>{this.props.data.info.date}</h4>
                    <Row className="mb-5">
                        <Col sm>
                        </Col>
                        <Col sm={9}>
                            <DoughnutChart data={this.genDoughnutChartData(tpex_labels, tpex_today_vol)} title="上櫃類股成交金額分佈" />
                        </Col>
                        <Col sm>
                        </Col>
                    </Row>
                </div>

                <div>
                    <h6>上櫃類股金流分佈</h6>
                    <p>觀察每日類股指數與成交比重增幅排名, 成交比重異常增加, 指數無明顯變化, 表示已有資金提前進入或撤出該類股.<br />
                        點擊類股名稱, 觀察類股成分, 分析該類股前幾大個股, 搭配法人籌碼, 基本面, 技術面等伺機佈局.
                    </p>
                </div>

                <hr />
                <AdSenseBanner />
                <div className="mr-auto mb-2">
                    <Button variant="primary" className="mr-2" size="sm" onClick={this.handleClick} value="index_rank">指數漲幅排名</Button>
                    <Button variant="primary" className="mr-2" size="sm" onClick={this.handleClick} value="weight_rank">成交比重排名</Button>
                    <Button variant="primary" className="mr-2" size="sm" onClick={this.handleClick} value="weight_rise_rank">成交比重增幅排名</Button>
                </div>
                <div>
                    <IndexMainChart data={this.genIndexMainChartData(label, index_change, weight, title)} title="電子金融比例"/>
                </div>

                {this.renderRankingList()}

            </div>
        )
    }
}

export default TpexIndexPanel