import React from "react";
import Seo from "../components/Seo";
import '../styles/index.css';

export default () => {
    return (
        <div className="container">
            <Seo
                title="關於 Joyride"
                description="Joyride 是一種精神, 或許每個人都需要放手一搏, 來場 Joyride."
                url="https://joyride.tw/about"
            />
            <div className="vertical-center">
                <h4>關於 Joyride</h4>
                <p>
                    Joyride 是一種精神, 或許每個人都需要放手一搏, 來場 Joyride.<br />
                    <br />
                    Joyride 喜行策略創立於 2019, 專注於金融研究, 加密貨幣研究, 量化數據分析, 交易策略設計以及工具開發, <br />
                    透過大數據分析與回測, 降低風險, 提升投資效率. <br />
                    我們相信歷史會一再重演, 只需要將風險壓縮在可控範圍, 剩下的就是放手一搏.
                </p>
                <br />
                <h4>連絡 Joyride</h4>
                <a href="mailto:cs@joyride.tw"><h5>cs@joyride.tw</h5></a>
            </div>
        </div>
    );
}