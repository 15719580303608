import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import styles from "./DoughnutChart.module.css";

export default ({data, title}) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true, //TODO: window.is_mobile ? false : true,
            position: 'right',
        },
        title: {
            display: true,
            text: title
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };

    return (
      <div className={styles.chart}>
        <Doughnut data={data} options={options} />
      </div>
    )
}