import React from "react";
import { Modal, Button } from 'react-bootstrap';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Error
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{props.message}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
    </Modal>
  );
}

export default ({data}) => {
    const [modalShow, setModalShow] = React.useState(true);
    return (
        <>
        <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            message={data}
        />
        </>
    );
}