import React from "react";
import AdSenseBanner from "../../components/AdSenseBanner";
import { Button } from "react-bootstrap";
import tablesort from "../../utils/tablesort.js";
import styles from "./ChipsPanel.module.css";
import { links } from "../../utils/utils";

class ChipsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            filter: "none",
        };
    }
    
    getRowStyle(item, info) {
        let w_buy = parseFloat(item.f_bs);
        let t_buy = parseFloat(item.t_bs);
        let filter = this.state.filter;
        let class_name = "";
        if (filter === "buy_together" && (t_buy > 0 && w_buy > 0)) {
            class_name = "table-danger";
        } else if (filter === "sell_together" && (t_buy < 0 && w_buy < 0)) {
            class_name = "table-success";
        } else if (filter === "not_together") {
            if ((t_buy > 0 && w_buy < 0) || (t_buy < 0 && w_buy > 0)) {
                class_name = "table-warning";
            }
        } else if (filter === "blue_strategy") {
            if (info.blue.indexOf(item.stock_id) > -1) {
                class_name = "table-primary";
            }
        } else if (filter === "grey_strategy") {
            if (info.grey.indexOf(item.stock_id) > -1) {
                class_name = "table-secondary";
            }
        }
        return class_name;
    }

    renderTableData(data) {
        console.log(this.state.filter);
        let items = data.items;
        let info = data.info;
        return items.map((item) => {
            return (
                <tr id={"tr_" + item.stock_id} key={item.stock_id} className={this.getRowStyle(item, info)}>
                    <td>
                        <a href={links.statementdog + item.stock_id} target="_blank" rel="noopener noreferrer">{item.stock_id}</a>
                    </td>
                    <td>
                        <a href={links.wantgoo + item.stock_id} target="_blank" rel="noopener noreferrer">{item.name}</a>
                    </td>
                    <td>{item.price}</td>
                    <td>{item.change_ratio}</td>
                    <td>{item.industry === null ? '' : item.industry}</td>
                    <td>{item.f_bs}</td>
                    <td>{item.f_buy_ratio}</td>
                    <td>{item.f_inventory}</td>
                    <td>{item.f_hold_ratio}</td>
                    <td>{item.f_cbs}</td>
                    <td>{item.t_bs}</td>
                    <td>{item.t_buy_ratio}</td>
                    <td>{item.t_inventory}</td>
                    <td>{item.t_hold_ratio}</td>
                    <td>{item.t_cbs}</td>
                    <td>{item.three_bs}</td>
                    <td>{item.three_hold_ratio}</td>
                </tr>
            );
        });
    }

    renderDivider(data) {
        if(data.length > 0) {
            return (<strong>{" | "}</strong>);
        } else {
            return (<strong>今日無標的中策</strong>);
        }
    }

    renderHintArea(info) {
        let filter = this.state.filter; 
        if (filter === "blue_strategy") {
            let blue = info.blue;
            let blue_name = info.blue_name;
            return (
                <div className="text-primary">
                    <h5>Joyride 潛在藍色策略</h5>
                    {this.renderDivider(blue)}
                    {blue.map((id, index) => {
                        return (
                            <strong key={"blue" + id}>
                                <a className="text-primary" href={links.statementdog + id} target="_blank" rel="noopener noreferrer">{id}</a>{" "}
                                <a className="text-primary" href={links.wantgoo + id} target="_blank" rel="noopener noreferrer">{blue_name[index]}</a> {" | "}
                            </strong>
                        );
                    })
                    }
                </div>
            );
        } else if (filter === "purple_strategy") {
            let grey = info.grey;
            let grey_name = info.grey_name;
            return (
                <div className="text-secondary">
                    <h5>Joyride 潛在紫色策略</h5>
                    {this.renderDivider(grey)}
                    {grey.map((id, index) => {
                        return (
                            <strong key={"grey" + id}>
                                <a className="text-secondary" href={links.statementdog + id} target="_blank" rel="noopener noreferrer">{id}</a>{" "}
                                <a className="text-secondary" href={links.wantgoo + id} target="_blank" rel="noopener noreferrer">{grey_name[index]}</a> {" | "}
                            </strong>
                        );
                    })
                    }
                </div>
            );
        } else {
            return (
                <div>
                    <h5>Joyride 法人籌碼</h5>
                    選股須搭配綜合資訊以評估是否該進場買進<br />
                    <strong>大盤多空與位階:</strong> 大盤偏空不宜做多.<br />
                    <strong>個股技術面:</strong> 價量上漲, 均線糾結打開, MACD 翻正, KD 黃金交叉向上, 乖離尚未過大, 突破均線等.<br />
                    <strong>個股基本面:</strong> pe band 位階, pb, 殖利率,目前股價是否符合或低於個股價值, 毛利率, 營收, eps 等是否成長等.<br />
                    <strong>個股消息面:</strong> 產業趨勢向上, 政府政策等.<br />
                    <strong>法人資訊:</strong> 近期是否有<a href={links.investor_conference} target="_blank" rel="noopener noreferrer">法說會</a>或財報公佈法人優先佈局, 參考法人分析報告與目標價等.
                </div>
            );
        }
    }

    handleClick(e) {
        this.setState({filter: e.target.value});
    }

    componentDidMount() {
        tablesort(this.chipsTable, {descending: true});
    }

    render() {
        return (
            <div className="container-fluid">
                <h4>{this.props.data.info.date}</h4>
                <hr />
                {this.renderHintArea(this.props.data.info)}
                <hr />
                <AdSenseBanner />
                <div className="mr-auto mb-2">
                    <Button variant="danger" className="mr-2" size="sm" onClick={this.handleClick} value="buy_together">外資投信同買</Button>
                    <Button variant="success" className="mr-2" size="sm" onClick={this.handleClick} value="sell_together">外資投信同賣</Button>
                    <Button variant="warning" size="sm" onClick={this.handleClick} value="not_together">外資投信對做</Button>
                </div>

                <div className={[styles.table_div, styles.main_table].join(' ')}>
                    <table className="table table-sm table-hover" ref={(table) => {this.chipsTable = table;}}>
                        <thead>
                            <tr className="table-head-row">
                                <th className={styles.cursor_pointer}>證券代號</th>
                                <th className={styles.cursor_pointer}>證券名稱</th>
                                <th className={styles.cursor_pointer}>收盤價</th>
                                <th className={styles.cursor_pointer}>漲跌幅</th>
                                <th className={styles.cursor_pointer}>產業類型</th>
                                <th className={styles.cursor_pointer}>外資買賣超張數</th>
                                <th className={styles.cursor_pointer}>外本比</th>
                                <th className={styles.cursor_pointer}>外資庫存張數</th>
                                <th className={styles.cursor_pointer}>外資持股比例</th>
                                <th className={styles.cursor_pointer}>外資連買賣</th>
                                <th className={styles.cursor_pointer}>投信買賣超張數</th>
                                <th className={styles.cursor_pointer} data-sort-default>投本比</th>
                                <th className={styles.cursor_pointer}>投信庫存張數</th>
                                <th className={styles.cursor_pointer}>投信持股比例</th>
                                <th className={styles.cursor_pointer}>投信連買賣</th>
                                <th className={styles.cursor_pointer}>三大法人買賣超張數</th>
                                <th className={styles.cursor_pointer}>三大法人持股比例</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableData(this.props.data)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default ChipsPanel