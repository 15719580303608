import React from "react";
import logo from "./logo.png";
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import '../utils/bootstrap.min.css';
import '../styles/index.css';


function tw_stock_dropdown() {
  return (
    <NavDropdown title="台股數據" id="stock-nav-dropdown">
        <NavDropdown.Item href="/tw-stock-market">大盤籌碼</NavDropdown.Item>
        <NavDropdown.Item href="/tw-stock-chips">法人籌碼</NavDropdown.Item>
        <NavDropdown.Item href="/twse-market-indices">上市產業</NavDropdown.Item>
        <NavDropdown.Item href="/tpex-market-indices">上市產業</NavDropdown.Item>
    </NavDropdown>
  );
}

function tw_strategy_dropdown() {
  return (
    <NavDropdown title="台股策略" id="strategy-nav-dropdown">
        <NavDropdown.Item href="/blue-strategy">藍色策略</NavDropdown.Item>
    </NavDropdown>
  );
}

function crypto_strategy_dropdown() {
  return (
    <NavDropdown title="加密策略" id="crypto-strategy-nav-dropdown">
        <NavDropdown.Item href="/red-strategy">紅色策略</NavDropdown.Item>
    </NavDropdown>
  );
}

export default () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="white" sticky="top">
        <Navbar.Brand href="/">
            <img src={logo} alt="Joyride" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto" />
            <Nav className="font-weight-bold">
                <Nav.Link href="/tw-stock-market">大盤籌碼</Nav.Link>
                <Nav.Link href="/tw-stock-chips">法人籌碼</Nav.Link>
                <Nav.Link href="/twse-market-indices">上市產業</Nav.Link>
                <Nav.Link href="/tpex-market-indices">上櫃產業</Nav.Link>
                {tw_strategy_dropdown()}
            </Nav>
        </Navbar.Collapse>
    </Navbar>
  )
}