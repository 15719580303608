import React from "react";
import ChipsPanel from "./panels/ChipsPanel";
import LoadingPanel from './panels/LoadingPanel';
import ErrorPanel from './panels/ErrorPanel';
import Seo from "../components/Seo";

class Chips extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                error: null,
                isload:false,
                items:[]
            }
        }
    }

    componentDidMount() {
        console.log("componentDidMount");
        fetch("/json/chips.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    renderPanel() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            console.log("error");
            return (
                <ErrorPanel data={error.message}/>
            );
        } else if (!isLoaded) {
            console.log("loading");
            return (
                <LoadingPanel />
            );
        } else {
            console.log("normal start");
            return (
                <ChipsPanel data={items} />
            );
        }
    }

    render() {
        return (
            <div>
                <Seo
                    title="追蹤法人籌碼"
                    description="追蹤法人籌碼. 每日更新外本比, 外資庫存張數, 外資持股比例, 投本比, 投信庫存張數, 投信持股比例, 買賣超金額, 以及選股策略. 快速過濾外資投信佈局, 同買賣對做股票."
                    url="https://joyride.tw/chips"
                    />
                {this.renderPanel()}
            </div>
        );
    }
}

export default Chips